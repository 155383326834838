import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN_URL,
  // baseURL: 'http://192.168.100.206:4001',
  headers: {
    'x-company-id': process.env.REACT_APP_COMPANY_ID,
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    if (process.env.REACT_APP_COMPANY_ID) {
      config.headers['company-id'] = process.env.REACT_APP_COMPANY_ID;

      if (config?.data) {
        config.data.companyId = process.env.REACT_APP_COMPANY_ID;
      }

      let token = localStorage.getItem('user-token');

      // RETRIVE RESPECTIVE TOKENS FOR EACH USER TYPE
      if (config.url?.split('/')[1] === 'dsr') {
        token = localStorage.getItem('dsr-token');
      } else if (config.url?.split('/')[1] === 'retailer') {
        token = localStorage.getItem('retailer-token');
      }
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    }
  },
  (error) => {
    console.log('error', error);
    return Promise.reject(error);
  }
);

export default axiosClient;