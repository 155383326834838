import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n

  .use(initReactI18next)

  .init({
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          'Login': 'Login',
          'to Account': 'to Account',
          'Please fill your number to login': 'Please fill your number to login',
          'Phone Number': 'Phone Number',
          'Number': 'Number',
          'Verification': 'Verification',
          'Please enter the verification code that was sent to': 'Please enter the verification code that was sent to',
          'Resend Code': 'Resend Code',
          'Resend Code in': 'Resend Code in',
          'Verify': 'Verify',
          'Your number has been verified!': 'Your number has been verified!',
          'Success!': 'Success!',
          'Unsuccessful': 'Unsuccessful',
          'It seems like the one-time PIN you entered is incorrect. Please try again with the correct code.': 'It seems like the one-time PIN you entered is incorrect. Please try again with the correct code.',
          'Try Again': 'Try Again',
          'New one-time PIN sent.': 'New one-time PIN sent.',
          'Please check your phone for the code.': 'Please check your phone for the code.',
          'Done': 'Done',
          'Registration': 'Registration',
          'Please fill the form to continue': 'Please fill the form to continue',
          'Name': 'Name',
          'Email (Optional)': 'Email (Optional)',
          "I agree to the Mead Johnson Nurition's": "I agree to the Mead Johnson Nurition's",
          "Terms and Conditions": "Terms and Conditions",
          "and the": "and the",
          "Privacy Policy": "Privacy Policy",
          '.': '.',
          'I authorise Mead Johnson Nutrition to disclose my personal data* to selected third parties to deliver value added services to me.': 'I authorise Mead Johnson Nutrition to disclose my personal data* to selected third parties to deliver value added services to me.',
          'Register': "Register",
          'Hi': 'Hi',
          'View Submission History': 'View Submission History',
          'Your Performance': 'Your Performance',
          'You are entitled': 'You are entitled',
          'Your Current Score': 'Your Current Score',
          'As of': 'As of',
          'Criteria': 'Criteria',
          'Utilization': 'Utilization',
          'New User': 'New User',
          'Weightage': 'Weightage',
          'Your Scoring': 'Your Scoring',
          'Your Pts = Scoring x Weightage': 'Your Pts = Scoring x Weightage',
          'Total': 'Total',
          'Leaderboard': 'Leaderboard',
          'Your Ranking': 'Your Ranking',
          'Qualification Status': 'Qualification Status',
          'Store': 'Store',
          'Total Rebate': 'Total Rebate',
          'Total Incentive': 'Total Incentive',
          'NU Rebate & Incentive': 'NU Rebate & Incentive',
          'Month': 'Month',
          'January': 'January',
          'February': 'February',
          'March': 'March',
          'April': 'April',
          'May': 'May',
          'June': 'June',
          'July': 'July',
          'August': 'August',
          'September': 'September',
          'October': 'October',
          'November': 'November',
          'December': 'December',
          'NU Rebate (RM)': 'NU Rebate (RM)',
          'A+ NU RM5 Incentive (RM)': 'A+ NU RM5 Incentive (RM)',
          'GE NU RM10 Incentive (RM)': 'GE NU RM10 Incentive (RM)',
          'Total Amt (RM)': 'Total Amt (RM)',
          'Mileage Rebate & Incentive': 'Mileage Rebate & Incentive',
          'Mileage RM40 Rebate (RM)': 'Mileage RM40 Rebate (RM)',
          'Mileage RM5 Incentive': 'Mileage RM5 Incentive',
          'Mileage RM10 Incentive (RM)': 'Mileage RM10 Incentive (RM)',
          'Allocation Status': 'Allocation Status',
          'Total A+ 3.48kg Allocation (Unit)': 'Total A+ 3.48kg Allocation (Unit)',
          'Total A+ 1.65kg Allocation (Unit)': 'Total A+ 1.65kg Allocation (Unit)',
          'Total GE Allocation (Unit)': 'Total GE Allocation (Unit)',
          'Total A+ 3.48kg Balance (Unit)': 'Total A+ 3.48kg Balance (Unit)',
          'Total A+ 1.65kg Balance (Unit)': 'Total A+ 1.65kg Balance (Unit)',
          'Total GE Balance (Unit)': 'Total GE Balance (Unit)',
          'Submission Summary': 'Submission Summary',
          'Total Submission': 'Total Submission',
          'Approved': 'Approved',
          'Pending': 'Pending',
          'Rejected': 'Rejected',
          'APPROVED': 'APPROVED',
          'PENDING': 'PENDING',
          'REJECTED': 'REJECTED',
          'Reason': 'Reason',
          'Rejected Reason': 'Rejected Reason',
          'Mileage Status': 'Mileage Status',
          ' Status': 'Status',
          'OR': 'OR',
          'Cashback': 'Cashback',
          'Units Purchased:': 'Units Purchased:',
          'Set 1/2': 'Set 1/2',
          'Set 2/2': 'Set 2/2',
          'Get <b>RM40 Cashback</b> for every 2 units of Enfagrow A+ 3.48kg / 3 units of Gentlease 1.6kg in single receipt': 'Get <b>RM40 Cashback</b> for every 2 units of Enfagrow A+ 3.48kg / 3 units of Gentlease 1.6kg in single receipt',
          "Touch 'N Go Reload Pin": "Touch 'N Go Reload Pin",
          "Touch 'n Go Reload PIN will only be reflected upon receipt approval. Validation period: 3-5 working days": "Touch 'n Go Reload PIN will only be reflected upon receipt approval. Validation period: 3-5 working days",
          "Welcome !": 'Welcome !',
          'Please show to retailer to get your cashback.': 'Please show to retailer to get your cashback.',
          'Submission History': 'Submission History',
          'T&Cs Apply.': 'T&Cs Apply.',
          'My Rewards': 'My Rewards',
          'Get Started': 'Get Started',
          'Upload Receipt': 'Upload Receipt',
          'Upload a picture of your receipt': 'Upload a picture of your receipt',
          'PNG, JPEG or PDF': 'PNG, JPEG or PDF',
          'Fill in the details:': 'Fill in the details:',
          'Product': 'Product',
          'Units': 'Units',
          'units(s)': 'units(s)',
          'Add Product +': 'Add Product +',
          'Upload Again': 'Upload Again',
          'Submit': "Submit",
          'Sample Receipt': "Sample Receipt",
          'Back': 'Back',
          'Sample Physical Receipt:': 'Sample Physical Receipt:',
          'Your receipt has been successfully uploaded and will be validated within 3 working days.': 'Your receipt has been successfully uploaded and will be validated within 3 working days.',
          "Touch 'n Go eWallet Reload PIN valued at RM": "Touch 'n Go eWallet Reload PIN valued at RM",
          'exp.': 'exp.',
          'How to redeem?': 'How to redeem?',
          'Terms & Conditions': 'Terms & Conditions',
          'Close': 'Close',
          'Only ONE (1) Reload PIN Reward can be used per reload.': 'Only ONE (1) Reload PIN Reward can be used per reload.',
          'To utilize the Reload Pin, log in on the microsite. At ‘Rewards’, tap on the ‘View Now’ button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.': 'To utilize the Reload Pin, log in on the microsite. At ‘Rewards’, tap on the ‘View Now’ button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.',
          'Used Reload Pin Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.': 'Used Reload Pin Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.',
          'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.': 'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.',
          'Reload Pin will be expired on': 'Reload Pin will be expired on',
          "How to Redeem Your Touch 'n Go Reload Pin?": "How to Redeem Your Touch 'n Go Reload Pin?",
          "Open the Touch 'n Go eWallet Reload PIN App": "Open the Touch 'n Go eWallet Reload PIN App",
          "Click ‘Reload’": "Click ‘Reload’",
          "Click ‘eWallet Balance’": "Click ‘eWallet Balance’",
          "Click on ‘TNG eWallet Reload PIN' below of the page": "Click on ‘TNG eWallet Reload PIN' below of the page",
          "Key in the TNG eWallet Reload PIN and click ‘Reload Now’": "Key in the TNG eWallet Reload PIN and click ‘Reload Now’",
          "Successfully redeem Touch 'n Go eWallet voucher":
            "Successfully redeem Touch 'n Go eWallet voucher",
          'Sounds Good!': 'Sounds Good!',
          'Copied to clipboard': 'Copied to clipboard',
          'Receipt:': 'Receipt',
          // Retailer
          'Total Shoppers Registered': 'Total Shoppers Registered',
          'Total New Users': 'Total New Users',
          'Total Allocation': 'Total Allocation',
          'Utilization Rate': 'Utilization Rate',
          'Customer Performance': 'Customer Performance',
          'New User': 'New User',
          'Sales (RM)': 'Sales (RM)',
          'Search': "Search",
          'Recruited Users': 'Recruited Users',
          'You are entitled cashback on your 1st purchase of participating products for New User Reward!': 'You are entitled cashback on your 1st purchase of participating products for New User Reward!',
          'You are entitled cashback on your 1st purchase!': 'You are entitled cashback on your 1st purchase!',
          '*1 user is only entitled for 1 New User Reward': '*1 user is only entitled for 1 New User Reward',
          '*Gentlease RM20 Cashback valid from 1st June - 31st July 2024.': '*Gentlease RM20 Cashback valid from 1st June - 31st July 2024.',
          'Important notification!': 'Important notification!',
          'Thank you for your support!': 'Thank you for your support!',
          'The A+ Mileage program has ended on 30th June 2024. However, you can still submit receipts for participating products purchased during campaign period (March 21,2024 - June 30, 2024),': 'The A+ Mileage program has ended on 30th June 2024. However, you can still submit receipts for participating products purchased during campaign period (March 21,2024 – June 30, 2024),',
          'by': 'by',
          'July 15, 2024.': 'July 15, 2024.'
        }
      },
      cn: {
        translation: {
          'Login': '登入',
          'to Account': '账号',
          'Please fill your number to login': '请使用电话号码登录',
          'Phone Number': '电话号码',
          'Number': '电话号码',
          'Verification': '验证',
          'Please enter the verification code that was sent to': '请填写发送到 016xxxxxxx 的验证码',
          'Resend Code': '重发验证码',
          'Resend Code in': '重发验证码',
          'Verify': '验证',
          'Your number has been verified!': '你的电话号码已被验证 !',
          'Success!': '成功 !',
          'Unsuccessful': '失败',
          'It seems like the one-time PIN you entered is incorrect. Please try again with the correct code.': '您所输入的OTP验证码不正确. 请使用正确的验证码再试一次.',
          'Try Again': '重试',
          'New one-time PIN sent.': 'New one-time PIN sent.',
          'Please check your phone for the code.': 'Please check your phone for the code.',
          'Done': '完成',
          'Registration': '注册',
          'Please fill the form to continue': '请填写表格以继续',
          'Name': '姓名',
          'Email (Optional)': '电邮',
          "I agree to the Mead Johnson Nurition's": "I agree to the Mead Johnson Nurition's",
          "Terms and Conditions": "Terms and Conditions",
          "and the": "and the",
          "Privacy Policy": "隐私政策",
          '.': '.',
          'I authorise Mead Johnson Nutrition to disclose my personal data* to selected third parties to deliver value added services to me.': '我授权 Mead Johnson Nutrition 将我的个人数据* 披露给特定的第三方，以向我提供增值服务',
          'Register': "注册",
          'Hi': '你好',
          'View Submission History': '查看提交记录',
          'Your Performance': '商家绩效',
          'You are entitled': 'You are entitled',
          'Your Current Score': '商家当前分数',
          'As of': '截至',
          'Criteria': '标准',
          'Utilization': '利用率',
          'New User': '新用户',
          'Weightage': '分配比重',
          'Your Scoring': '商家得分',
          'Your Pts = Scoring x Weightage': '商家分数 = (得分 x 分配比重)',
          'Total': '总得',
          'Leaderboard': '排行榜',
          'Your Ranking': '您的排名',
          'Qualification Status': '资格状态',
          'Store': '商家',
          'Total Rebate': '总现金回扣',
          'Total Incentive': '总奖励',
          'NU Rebate & Incentive': '新用户现金回扣及奖励',
          'Month': '月份',
          'January': '一月',
          'February': '二月',
          'March': '三月',
          'April': '四月',
          'May': '五月',
          'June': ' 六月',
          'July': '七月',
          'August': '八月',
          'September': '九月',
          'October': '十月',
          'November': '十一月',
          'December': '十二月',
          'NU Rebate (RM)': '新用户 现金回扣 (RM)',
          'A+ NU RM5 Incentive (RM)': 'A+新用户 RM5奖励 (RM)',
          'GE NU RM10 Incentive (RM)': 'GE 新用户 RM10奖励 (RM)',
          'Total Amt (RM)': '总额 (RM)',
          'Mileage Rebate & Incentive': '礼程现金回扣及奖励',
          'Mileage RM40 Rebate (RM)': '礼程RM40 现金回扣 (RM)',
          'Mileage RM5 Incentive (RM)': '礼程RM5 奖励 (RM)',
          'Mileage RM10 Incentive (RM)': '礼程RM10 奖励 (RM)',
          'Allocation Status': '分配进度',
          'Total A+ 3.48kg Allocation (Unit)': '总分配 (单位）',
          'Total A+ 1.65kg Allocation (Unit)': '总A+分配 (单位)',
          'Total GE Allocation (Unit)': '总GE分配 (单位)',
          'Total A+ 3.48kg Balance (Unit)': '总余额 (单位)',
          'Total A+ 1.65kg Balance (Unit)': '总A+余额 (单位)',
          'Total GE Balance (Unit)': '总GE余额 (单位)',
          'Submission Summary': '提交总结',
          'Total Submission': '总提交数',
          'Approved': '已批准',
          'Pending': '待处理',
          'Rejected': '未通过',
          'APPROVED': '已批准',
          'PENDING': '待处理',
          'REJECTED': '未通过',
          'Reason': '拒绝原因',
          'Rejected Reason': '拒绝原因',
          'Mileage Status': '礼程状态',
          'Status': '状态',
          'Cashback': '现金回扣',
          'Units Purchased:': '购买单位:',
          'Set 1/2': '第1/2套',
          'Set 2/2': '第2/2套',
          'Get <b>RM40 Cashback</b> for every 2 units of Enfagrow A+ 3.48kg / 3 units of Gentlease 1.6kg in single receipt': 'Get <b>RM40 Cashback</b> for every 2 units of Enfagrow A+ 3.48kg / 3 units of Gentlease 1.6kg in single receipt',
          "Touch 'N Go Reload Pin": "Touch 'N Go 充值密码",
          "Touch 'n Go Reload PIN will only be reflected upon receipt approval. Validation period: 3-5 working days": "Touch 'n Go 充值码将在收据审核通过后显示。验证期为：3-5个工作日",
          "Welcome !": '欢迎!',

          'Please show to retailer to get your cashback.': '请向零售商展示以获得您的现金回扣',
          'Submission History': '提交历史',
          'T&Cs Apply.': '条款与条件适用',
          'My Rewards': '我的奖励',
          'Get Started': '立即开始',
          'Upload Receipt': '上传收据',
          'Upload a picture of your receipt': '上传您的收据照片',
          'PNG, JPEG or PDF': 'PNG、JPEG或PDF格式',
          'Fill in the details:': '填写详细信息',
          'Product': '产品',
          'Units': '单位',
          'units(s)': '单位',
          'Add Product +': '添加产品 +',
          'Upload Again': '重新上传',
          'Submit': "提交",
          'Sample Receipt': "收据示例",
          'Back': '返回',
          'Sample Physical Receipt:': '实体收据样本:',
          'Your receipt has been successfully uploaded and will be validated within 3 working days.': '您的收据已成功上传，将在3 - 5个工作日内完成验证.',
          "Touch 'n Go eWallet Reload PIN valued at RM": "Touch 'n Go电子钱包充值码，价值RM10",
          'exp.': '有效期至',
          'How to redeem?': '如何兑换？',
          'Terms & Conditions': '条款与条件',
          'Close': '关闭',
          'Only ONE (1) Reload PIN Reward can be used per reload.': '每次充值只能使用一（1）个充值码',
          'To utilize the Reload Pin, log in on the microsite. At ‘Rewards’, tap on the ‘View Now’ button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.': '要使用充值密码，请登录微站。在“奖励”处，点击您奖励下的“立即查看”按钮。然后，点击相应奖励券码旁的复制图标，并按照提供的步骤操作。',
          'Used Reload Pin Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.': '使用过的充值码奖励不可逆转。一旦使用，金额不能兑换成积分、现金或部分或全部的替代物。',
          'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.': 'TNG Digital在此活动中不会以任何方式联系用户索取任何个人信息或账户凭证。',
          'Reload Pin will be expired on': '充值码将于[年]年[月][日]过期',
          "How to Redeem Your Touch 'n Go Reload Pin?": "如何兑换您的 Touch 'n Go 充值密码？",
          "Open the Touch 'n Go eWallet Reload PIN App": "打开 Touch 'n Go eWallet 充值密码应用程序",
          "Click ‘Reload’": "点击“充值”",
          "Click ‘eWallet Balance’": "点击“电子钱包余额”",
          "Click on ‘TNG eWallet Reload PIN' below of the page": "在页面下方点击“TNG 电子钱包充值密码”",
          "Key in the TNG eWallet Reload PIN and click ‘Reload Now’": "输入 TNG 电子钱包充值码并点击“立即充值”",
          "Successfully redeem Touch 'n Go eWallet voucher":
            "成功充值 Touch 'n Go 电子钱包",
          'Sounds Good!': '了解！',
          'Copied to clipboard': 'Copied to clipboard',
          'Receipt:': '收据:',
          // Retailer
          'Total Shoppers Registered': '总注册的顾客',
          'Total New Users': '新用户总数',
          'Total Allocation': '总分配',
          'Utilization Rate': '利用率',
          'Customer Performance': '顾客表现',
          'New User': '顾客表现',
          'Sales (RM)': '销售额 (RM)',
          'Search': "搜索",
          'Recruited Users': '注册的顾客',
          'You are entitled cashback on your 1st purchase of participating products for New User Reward!': '作为新用户奖励您在首次购买任何参与产品时便能立即获得现金回扣！',
          'You are entitled cashback on your 1st purchase!': '您能在首次购买参与产品享有新用户奖励的现金回扣!',
          '*1 user is only entitled for 1 New User Reward': '*每位新用户仅可获得 1次新用户奖励.',
          '*Gentlease RM20 Cashback valid from 1st June - 31st July 2024.': '*Gentlease RM20 现金回扣有效期为2024 年6月1日至7月31日.',
          'Important notification!': '重要通知 ！',
          'Thank you for your support!': '感谢您的支持 ！',
          'The A+ Mileage program has ended on 30th June 2024. However, you can still submit receipts for participating products purchased during campaign period (March 21,2024 - June 30, 2024),': 'A+ 礼程宝计划已于2024年6月30日结束。然而,您仍然可以提交在活动期间 (2024 年 3 月 21 日 至 2024 年 6 月 30 日）购买的参与产品收据，',
          'by': '截止日期为',
          'July 15, 2024.': '2024 年 7月 15 日。',
        }
      }
    },
    lng: localStorage.getItem("language") && localStorage.getItem("language") === "en" ? "en" : "cn"
  });

export default i18n;
