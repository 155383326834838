import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "./utils/axiosClient.js";
import { saveUser } from "./redux/slices/userSlice";
import { message } from 'antd';
import i18n from './utils/i18n.js';

const Loading = lazy(() => import("./user/pages/Loading.js"));
const Login = lazy(() => import("./user/pages/Login.js"));
const Verify = lazy(() => import("./user/pages/Verify.js"));
const Register = lazy(() => import("./user/pages/Register.js"));
const Home = lazy(() => import("./user/pages/Home.js"));
const RetailerHome = lazy(() => import("./retailer/pages/Home.js"));
const CustomerPerformance = lazy(() => import("./retailer/pages/CustomerPerformance.js"));
const Upload = lazy(() => import("./user/pages/UploadReceipt.js"));
const Rewards = lazy(() => import("./user/pages/Rewards.js"));
const Submissions = lazy(() => import("./user/pages/Submissions.js"));

function App() {
  const dispatch = useDispatch();
  const userToken = localStorage.getItem('user-token')
  const [language, setLanguage] = useState(localStorage.getItem("language"));

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    }
  }, [language]);

  // useEffect(() => {
  //   const check = async () => {
  //     try {
  //       const res = await axiosClient.get('/users/details');

  //       if (res) {
  //         dispatch(saveUser(res.data.data.personalInfo))
  //         // console.log(res.data.data)
  //         // console.log(res.data)
  //       }

  //     } catch (error) {
  //       message.error(error.message);
  //     }
  //   };
  //   if (userToken) {
  //     check()
  //   }
  // }, [])

  return (
    <div className="App" >
      {" "}
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/login" element={<Login language={language} setLanguage={setLanguage} />} />
            <Route path="/verify" element={<Verify language={language} setLanguage={setLanguage} />} />
            <Route path="/register" element={<Register language={language} setLanguage={setLanguage} />} />
            <Route path="/home" element={<Home language={language} setLanguage={setLanguage} />} />
            <Route path="/retailer/home" element={<RetailerHome language={language} setLanguage={setLanguage} />} />
            <Route path="/retailer/customer" element={<CustomerPerformance language={language} setLanguage={setLanguage} />} />
            <Route path="/upload" element={<Upload language={language} setLanguage={setLanguage} />} />
            <Route path="/rewards" element={<Rewards language={language} setLanguage={setLanguage} />} />
            <Route path="/submissions" element={<Submissions language={language} setLanguage={setLanguage} />} />
            <Route path="*" element={<Navigate to="/login" language={language} setLanguage={setLanguage} />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div >
  );
}

export default App;
